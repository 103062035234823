.MuiToggleButtonGroup-grouped{
    border-radius: 100px !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    color:black !important;
   
}

.MuiToggleButtonGroup-grouped:hover{
    background-color: rgb(0,0,0,0.2) !important;
}

.MuiToggleButtonGroup-grouped.Mui-selected{
    background-color: rgb(0,0,0,1) !important;
    color:white !important;
}