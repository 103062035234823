#opera{
    background-color: rgb(253, 194, 64) !important;
}
#teater{
    background-color: rgb(174, 224, 215) !important;
   
}
#balett{
    background-color: rgb(240, 80, 27) !important;
}

#konsert{
    background-color: rgb(207, 186, 225) !important;
   
}

#beskrivning-kort{
    text-overflow:ellipsis !important;
    overflow:hidden !important;
    
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
    
}