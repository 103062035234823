#nav{
    
    background-color: white;  
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24);;
}

#nav-inner{
   padding-top:10px;
    
}

