#up-button{
    left:50%;
    transform: translateX(-50%);
    
    z-index: 100;
    max-width: 1200px;
    width: 100%;
    display: flex;
    position: fixed;
    top: 10px;
    justify-content:flex-end;
    
   
   
}


