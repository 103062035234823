

#headline::before{

    
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        
        background-color: rgba(0, 0, 0, 0.4);
      
    
}


