




legend{
    width: 0;
    
}



.MuiMenu-list{
    padding-top:0 !important;
    padding-bottom:0 !important;
}

fieldset{
    border-width: 0px !important;
    background-color: rgb(0,0,0,0.1);
}

.MuiOutlinedInput-root:hover{
    background-color: rgb(0,0,0,0.15) !important;
}

