
.close {
    color: #fff; 
    opacity: 1;
    background-color: #00000062 !important;
    border-radius: 50%;
    line-height: 0px !important;
    width:35px;
    height:35px;  
    border: 0 !important;
    z-index:3;
}
.close:hover{
    color: rgba(255, 255, 255, 0.384);
    background-color:#0000003b !important;
    cursor:pointer;
}
#card-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
#card-img-overlay{
    background: linear-gradient(#00000000 20%, #000000);
    width: 100% !important;
    position: absolute;
    padding: 20px;
    top:0;
    height: 100%;

    
}

#modal-top::before{
    content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        
        background: linear-gradient(#00000000 20%, #000000);
}

